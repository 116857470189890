import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg height="45" width="150">
      <text x="0" y="15" className='svg-logo'>EBOW</text>
    </svg>
  );
};

export default Logo;